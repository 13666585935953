/*---------------------------------------
  imports
---------------------------------------*/

@import 'styles/variables.scss';

/*---------------------------------------
  menu button styles
---------------------------------------*/

.menu-button {
  position: fixed;
  top: 40px;
  left: 50px;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.menu-button__line {
  height: 4px;
  background-color: $colorGreyRegular;

  &+.menu-button__line {
    margin-top: 4px;
  }

  &:nth-child(1) {
    width: 25px;
  }

  &:nth-child(2) {
    width: 15px;
  }

  &:nth-child(3) {
    width: 20px;
  }
}