@import url(https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display:700&display=swap);
/*---------------------------------------
  global imports
---------------------------------------*/
/*---------------------------------------
  Font Variables
---------------------------------------*/
/*---------------------------------------
  Color Variables
---------------------------------------*/
/*---------------------------------------
  global styles
---------------------------------------*/
* {
  box-sizing: border-box; }

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0; }

body {
  font-family: "Nunito", sans-serif;
  color: #4F4F4F; }

p, a, h1, h2, h3, h4, h5, ul, li {
  margin: 0;
  padding: 0; }

a {
  text-decoration: none; }

#root {
  display: flex;
  overflow-x: hidden;
  height: 100%;
  width: 100%; }

/*---------------------------------------
  imports
---------------------------------------*/
/*---------------------------------------
  Font Variables
---------------------------------------*/
/*---------------------------------------
  Color Variables
---------------------------------------*/
/*---------------------------------------
  app styles
---------------------------------------*/
.app {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  display: flex; }

.mobile-overlay {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: -webkit-linear-gradient(330deg, #ff6085, #ff9665);
  background: linear-gradient(120deg, #ff6085, #ff9665);
  z-index: 100;
  color: white; }

@media only screen and (min-width: 900px) {
  .mobile-overlay {
    display: none !important;
    z-index: -100 !important; } }

/*---------------------------------------
  imports
---------------------------------------*/
/*---------------------------------------
  Font Variables
---------------------------------------*/
/*---------------------------------------
  Color Variables
---------------------------------------*/
/*---------------------------------------
  sidebar styles
---------------------------------------*/
.sidebar {
  position: relative;
  height: 100%;
  width: 500px;
  background-color: white;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  min-width: 0px;
  overflow-y: scroll; }

.sidebar--collapsed {
  width: 0px;
  overflow: hidden; }

.sidebar__content {
  position: absolute;
  top: 70px;
  left: 60px;
  padding-right: 50px;
  padding-bottom: 50px; }
  .sidebar__content p, .sidebar__content li {
    min-width: 350px; }

.sidebar__list {
  list-style: none; }

.sidebar__list-item {
  margin-left: 30px;
  margin-top: 20px; }

.sidebar__heading {
  margin-top: 30px;
  font-weight: 700; }

.sidebar__link {
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  color: #4F4F4F; }
  .sidebar__link:hover {
    color: #f8844e; }

.sidebar__link--active {
  color: #f8844e; }

.sidebar__text {
  margin-left: 30px;
  margin-top: 20px; }

.sidebar__social {
  margin-left: 30px;
  margin-top: 20px;
  display: flex; }

.sidebar__social-single {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #ffa074;
  color: white;
  cursor: pointer; }

.sidebar__social-single + .sidebar__social-single {
  margin-left: 10px; }

.fab, .far {
  color: white;
  font-size: 14px; }

/*---------------------------------------
  imports
---------------------------------------*/
/*---------------------------------------
  Font Variables
---------------------------------------*/
/*---------------------------------------
  Color Variables
---------------------------------------*/
/*---------------------------------------
  main content styles
---------------------------------------*/
.main {
  display: flex;
  position: relative;
  width: 100vw;
  height: 100%;
  background-color: #FAFAF9;
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out; }

.main--collapsed {
  width: 500px; }

.main__left-panel {
  flex: 1 0 auto;
  position: relative; }

.main__right-panel {
  flex: 0 0 500px;
  background: -webkit-linear-gradient(330deg, #ff6085, #ff9665);
  background: linear-gradient(120deg, #ff6085, #ff9665); }

.menu-button {
  position: fixed;
  top: 40px;
  left: 50px;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer; }

.menu-button__line {
  height: 4px;
  background-color: #BDBDBD; }
  .menu-button__line + .menu-button__line {
    margin-top: 4px; }
  .menu-button__line:nth-child(1) {
    width: 25px; }
  .menu-button__line:nth-child(2) {
    width: 15px; }
  .menu-button__line:nth-child(3) {
    width: 20px; }

/*---------------------------------------
  name and designation styles
---------------------------------------*/
.name {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 600px; }

.name__text {
  font-weight: 700;
  font-size: 1.1em; }

.name__occupation {
  color: #BDBDBD;
  margin-top: 2px; }

/*---------------------------------------
  card styles
---------------------------------------*/
.card {
  display: flex;
  align-items: stretch;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  height: auto;
  min-height: 400px;
  width: 650px;
  right: 300px;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
  z-index: 2; }

.card--fade .card__project-title, .card--fade .card__body-value {
  opacity: 0; }

.card__content {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: auto; }

.card__body {
  display: flex;
  flex-wrap: wrap;
  padding: 25px 45px;
  padding-bottom: 0px;
  margin-top: -20px; }

.card__body-item {
  width: 50%;
  margin-top: 20px; }

.card__body-heading {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 0.9em; }

.card__body-value {
  color: #f8844e;
  font-size: 0.9em;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }

.card__footer {
  margin-top: auto;
  padding: 20px 45px;
  padding-top: 0px; }

.card__more-button {
  margin-bottom: 20px;
  background: transparent;
  text-transform: uppercase;
  outline: none;
  border: none;
  padding: 0;
  font-size: 0.8em;
  color: #BDBDBD;
  cursor: pointer;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }
  .card__more-button:hover {
    color: #f8844e; }

.card__navigation {
  list-style: none;
  display: flex; }

.card__navigation-item {
  background: #E0E0E0;
  opacity: 0.4;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  cursor: pointer;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }
  .card__navigation-item:hover {
    opacity: 1; }
  .card__navigation-item + .card__navigation-item {
    margin-left: 5px; }

.card__navigation-item--active {
  opacity: 1; }

.card__header-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }

.card__header {
  display: flex;
  align-items: flex-end;
  padding: 0px 45px;
  position: relative;
  align-self: stretch;
  height: 150px; }

.card__notches {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 10px; }

.card__notch {
  height: 50%; }
  .card__notch:first-child {
    background-color: #ff7e9c; }
  .card__notch:last-child {
    background-color: #ffb28f; }

.card__project-title {
  font-family: "Playfair Display", serif;
  font-size: 1.9em;
  line-height: 130%;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }

.card__project-name {
  color: #BDBDBD; }

.card__image {
  flex: 0 0 200px;
  background: url(/static/media/full-bloom.88bf4ef9.png);
  background-repeat: repeat; }

/*---------------------------------------
  imports
---------------------------------------*/
/*---------------------------------------
  Font Variables
---------------------------------------*/
/*---------------------------------------
  Color Variables
---------------------------------------*/
/*---------------------------------------
  menu button styles
---------------------------------------*/
.menu-button {
  position: fixed;
  top: 40px;
  left: 50px;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer; }

.menu-button__line {
  height: 4px;
  background-color: #BDBDBD; }
  .menu-button__line + .menu-button__line {
    margin-top: 4px; }
  .menu-button__line:nth-child(1) {
    width: 25px; }
  .menu-button__line:nth-child(2) {
    width: 15px; }
  .menu-button__line:nth-child(3) {
    width: 20px; }

/*---------------------------------------
  imports
---------------------------------------*/
/*---------------------------------------
  Font Variables
---------------------------------------*/
/*---------------------------------------
  Color Variables
---------------------------------------*/
/*---------------------------------------
  sidebar styles
---------------------------------------*/
.project {
  display: flex;
  height: 100%;
  width: calc(100vw - 500px);
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  align-items: stretch;
  overflow: hidden; }

.project--collapsed {
  opacity: 0;
  width: 0px; }

.project__content {
  flex: auto;
  padding: 50px 80px;
  overflow: scroll;
  min-width: calc(100vw - 500px - 120px); }

.project__close-button {
  display: flex;
  flex: 0 0 120px;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #E0E0E0;
  cursor: pointer;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }
  .project__close-button button {
    background: transparent;
    border: none;
    font-size: 1.1em;
    outline: none;
    color: #E0E0E0;
    letter-spacing: 0.3em;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    -webkit-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    pointer-events: none; }
  .project__close-button:hover {
    border-left: 1px solid #BDBDBD; }
    .project__close-button:hover button {
      color: #BDBDBD; }

/*---------------------------------------
  child project styles
---------------------------------------*/
.project__wrapper {
  flex: auto;
  max-width: 100%;
  width: 100%;
  overflow-x: visible; }
  .project__wrapper a {
    color: #ff7e9c; }

.project__body {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: visible; }

.project__title {
  font-family: "Playfair Display", serif;
  font-size: 2.3em;
  line-height: 150%;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  width: 100%;
  margin-bottom: 20px; }

.project__feature-image {
  width: 100%;
  height: auto;
  align-self: center;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  margin-top: 30px;
  margin-bottom: 20px; }

.project__body-text {
  margin-top: 15px;
  line-height: 190%; }
  .project__body-text .bold {
    font-weight: 700; }

