/*---------------------------------------
  Font Variables
---------------------------------------*/

// Font Family Variables

$fontPrimary: 'Nunito', sans-serif;
$fontHeadings: 'Playfair Display', serif;

// Font Weight Variables

$fontWeightRegular: 400;
$fontWeightBold: 700;

/*---------------------------------------
  Color Variables
---------------------------------------*/

// Greyscale Color Variables

$colorGreyExtraLight: #FAFAF9;
$colorGreyLight: #E0E0E0;
$colorGreyRegular: #BDBDBD;
$colorGreyMedium: #828282;
$colorGreyDark: #4F4F4F;
$colorGreyExtraDark: #333333;

// Main Color Variables

$colorPrimary: rgb(255, 126, 156);
$colorSecondary: rgb(255, 178, 143);

// Gradient Variables

$gradientPrimary: linear-gradient(120deg, rgb(255, 96, 133), rgb(255, 150, 101));