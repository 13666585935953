/*---------------------------------------
  global imports
---------------------------------------*/

//Internal Imports

@import 'styles/variables.scss';

// External Imports

@import url('https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:700&display=swap');

/*---------------------------------------
  global styles
---------------------------------------*/

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: $fontPrimary;
  color: $colorGreyDark;
}

p, a, h1, h2, h3, h4, h5, ul, li {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

#root {
  display: flex;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}