/*---------------------------------------
  imports
---------------------------------------*/

@import 'styles/variables.scss';

/*---------------------------------------
  sidebar styles
---------------------------------------*/

.sidebar {
  position: relative;
  height: 100%;
  width: 500px;
  background-color: white;
  transition: all 300ms ease-in-out;
  min-width: 0px;
  overflow-y: scroll;
}

.sidebar--collapsed {
  width: 0px;
  overflow: hidden;
}

.sidebar__content {
  position: absolute;
  top: 70px;
  left: 60px;
  padding-right: 50px;
  padding-bottom: 50px;

  p, li {
    min-width: 350px;
  }

}

.sidebar__list {
  list-style: none;
}

.sidebar__list-item {
  margin-left: 30px;
  margin-top: 20px;
}

.sidebar__heading {
  margin-top: 30px;
  font-weight: $fontWeightBold;
}

.sidebar__link {
  transition: all 300ms ease-in-out;
  cursor: pointer;
  color: $colorGreyDark;

  &:hover {
    color: rgb(248, 132, 78);
  }
}

.sidebar__link--active {
  color: rgb(248, 132, 78);
}

.sidebar__text {
  margin-left: 30px;
  margin-top: 20px;
}

.sidebar__social {
  margin-left: 30px;
  margin-top: 20px;
  display: flex;
}

.sidebar__social-single {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: rgb(255, 160, 116);
  color: white;
  cursor: pointer;
}

.sidebar__social-single+.sidebar__social-single {
  margin-left: 10px;
}

.fab, .far {
  color: white;
  font-size: 14px;
}