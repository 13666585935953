/*---------------------------------------
  imports
---------------------------------------*/

@import 'styles/variables.scss';

/*---------------------------------------
  sidebar styles
---------------------------------------*/

.project {
  display: flex;
  height: 100%;
  width: calc(100vw - 500px);
  transition: all 500ms ease-in-out;
  align-items: stretch;
  overflow: hidden;
}

.project--collapsed {
  opacity: 0;
  width: 0px;
}

.project__content {
  flex: auto;
  padding: 50px 80px;
  overflow: scroll;
  min-width: calc(100vw - 500px - 120px);
}

.project__close-button {
  display: flex;
  flex: 0 0 120px;
  justify-content: center;
  align-items: center;
  border-left: 1px solid $colorGreyLight;
  cursor: pointer;
  transition: all 300ms ease-out;

  button {
    background: transparent;
    border: none;
    font-size: 1.1em;
    outline: none;
    color: $colorGreyLight;
    letter-spacing: 0.3em;
    transform: rotate(270deg);
    transition: all 300ms ease-out;
    pointer-events: none;
  }

  &:hover {
    border-left: 1px solid $colorGreyRegular;
    button {
      color: $colorGreyRegular;
    }
  }

}

/*---------------------------------------
  child project styles
---------------------------------------*/

.project__wrapper {
  flex: auto;
  max-width: 100%;
  width: 100%;
  overflow-x: visible;

  a {
    color: $colorPrimary;
  }
}

.project__body {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: visible;
}

.project__title {
  font-family: $fontHeadings;
  font-size: 2.3em;
  line-height: 150%;
  transition: all 300ms ease-in-out;
  width: 100%;
  margin-bottom: 20px;
}

.project__feature-image {
  width: 100%;
  height: auto;
  align-self: center;
  box-shadow: 0px 0px 30px rgba(0,0,0,0.15);
  margin-top: 30px;
  margin-bottom: 20px;
}

.project__body-text {
  margin-top: 15px;
  line-height: 190%;

  .bold {
    font-weight: $fontWeightBold;
  }
}