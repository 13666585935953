/*---------------------------------------
  imports
---------------------------------------*/

@import 'styles/variables.scss';

/*---------------------------------------
  main content styles
---------------------------------------*/

.main {
  display: flex;
  position: relative;
  width: 100vw;
  height: 100%;
  background-color: $colorGreyExtraLight;
  transition: all 500ms ease-in-out;
}

.main--collapsed {
  width: 500px;
}

.main__left-panel {
  flex: 1 0 auto;
  position: relative;
}

.main__right-panel {
  flex: 0 0 500px;
  background: $gradientPrimary;
}

.menu-button {
  position: fixed;
  top: 40px;
  left: 50px;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.menu-button__line {
  height: 4px;
  background-color: $colorGreyRegular;

  &+.menu-button__line {
    margin-top: 4px;
  }

  &:nth-child(1) {
    width: 25px;
  }

  &:nth-child(2) {
    width: 15px;
  }

  &:nth-child(3) {
    width: 20px;
  }
}


/*---------------------------------------
  name and designation styles
---------------------------------------*/

.name {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 600px;
}

.name__text {
  font-weight: $fontWeightBold;
  font-size: 1.1em;
}

.name__occupation {
  color: $colorGreyRegular;
  margin-top: 2px;
}

/*---------------------------------------
  card styles
---------------------------------------*/

.card {
  display: flex;
  align-items: stretch;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
  height: auto;
  min-height: 400px;
  width: 650px;
  right: 300px;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
  z-index: 2;
}

.card--fade {
  .card__project-title, .card__body-value {
    opacity: 0;
  }
}

.card__content {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: auto;
}

.card__body {
  display: flex;
  flex-wrap: wrap;
  padding: 25px 45px;
  padding-bottom: 0px;
  margin-top: -20px;
}

.card__body-item {
  width: 50%;
  margin-top: 20px;
}

.card__body-heading {
  position: relative;
  width: fit-content;
  font-size: 0.9em;
}

.card__body-value {
  color: rgb(248, 132, 78);
  font-size: 0.9em;
  transition: all 300ms ease-in-out;
}

.card__footer {
  margin-top: auto;
  padding: 20px 45px;
  padding-top: 0px;
}

.card__more-button {
  margin-bottom: 20px;
  background: transparent;
  text-transform: uppercase;
  outline: none;
  border: none;
  padding: 0;
  font-size: 0.8em;
  color: $colorGreyRegular;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &:hover {
    color: rgb(248, 132, 78);
  }
}

.card__navigation {
  list-style: none;
  display: flex;
}

.card__navigation-item {
  background: $colorGreyLight;
  opacity: 0.4;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &:hover {
    opacity: 1;
  }

  &+.card__navigation-item {
    margin-left: 5px;
  }
}

.card__navigation-item--active {
  opacity: 1;
}

.card__header-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.card__header {
  display: flex;
  align-items: flex-end;
  padding: 0px 45px;
  position: relative;
  align-self: stretch;
  height: 150px;
}

.card__notches {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 10px;
}

.card__notch {
  height: 50%;

  &:first-child {
    background-color: $colorPrimary;
  }

  &:last-child {
    background-color: $colorSecondary;
  }
}

.card__project-title {
  font-family: $fontHeadings;
  font-size: 1.9em;
  line-height: 130%;
  transition: all 300ms ease-in-out;
}

.card__project-name {
  color: $colorGreyRegular;
}

.card__image {
  flex: 0 0 200px;
  background: url('~assets/full-bloom.png');
  background-repeat: repeat;
}