/*---------------------------------------
  imports
---------------------------------------*/

@import 'styles/variables.scss';

/*---------------------------------------
  app styles
---------------------------------------*/

.app {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  min-width: min-content;
  display: flex;
}

.mobile-overlay {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: $gradientPrimary;
  z-index: 100;
  color: white;
}

@media only screen and (min-width: 900px) {
  .mobile-overlay {
    display: none !important;
    z-index: -100 !important;
  }
}